const merge = require('lodash/merge');
const config = require('./config.common');

module.exports = merge({}, config, {
  environment: 'dev',
  isPublisherActive: true,
  app: {
    protocol: 'https',
    host: 'prog.dev.viooh.net.cn',
    port: null,
  },
  api: {
    BASE_URL: 'https://gateway-api.devel.viooh.net.cn/',
  },
  keycloak: {
    url: 'https://keycloak.devel.viooh.net.cn/',
    realm: 'dev-cn',
    clientId: 'trading-manager-ui-front',
  },
  authProvider: 'Keycloak',
  thoughtspot: {
    host: 'https://viooh.thoughtspot.cloud/',
    identifier: 'development',
    pacingBoardId: '9d5b520e-4daf-4da6-91b8-86a64d985deb',
    dealHealthDashboardId: '7f0bc617-092f-464b-9490-cabe78f5f433',
    resellerDealHealthDashboardId: '17a46a4d-598b-44fe-b4d8-d6042e69ab5d',
  },
  features: (market) => {
    return {
      ...config.features(market),
      GOOGLEMAPS: false,
    };
  },
});
